@import '../../style/constantes.less';

.notifications {
  .ant-btn {
    width: 216px;
  }
  &-navbar {
    margin-left: 20px;
    margin-right: 20px;
    &-menu {
      display: flex;
      &-item {
        height: 28px;
        background-color: @substrate-color;
        font-family: @font-roboto;
        font-size: 14px;
        text-align: center;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding-top: 4px;
        margin-right: 9px;
        cursor: pointer;
      }
    }
  }
}

@primary-color: #1DA57A;