@import '../../style/constantes.less';

.administration-projects {
  .ant-btn {
    width: 216px;
  }
  .ant-table {
    tr {
      th:nth-of-type(2),
      td:nth-of-type(2) {
        border-left: none;
      }
    }
  }
  .ant-input-affix-wrapper,
  .ant-input {
    text-align: left;
    border-radius: 10px;
    font-family: @font-roboto;
    font-size: 14px;
  }
}

@primary-color: #1DA57A;