@import './constantes.less';

// top
.mt0 {
  margin-top: 0px;
}
.mt1 {
  margin-top: 1px;
}
.mt2 {
  margin-top: 2px;
}
.mt3 {
  margin-top: 3px;
}
.mt4 {
  margin-top: 4px;
}
.mt5 {
  margin-top: 5px;
}
.mt6 {
  margin-top: 6px;
}
.mt7 {
  margin-top: 7px;
}
.mt8 {
  margin-top: 8px;
}
.mt9 {
  margin-top: 9px;
}
.mt10 {
  margin-top: 10px;
}
.mt11 {
  margin-top: 11px;
}
.mt12 {
  margin-top: 12px;
}
.mt13 {
  margin-top: 13px;
}
.mt14 {
  margin-top: 14px;
}
.mt15 {
  margin-top: 15px;
}
.mt16 {
  margin-top: 16px;
}
.mt17 {
  margin-top: 17px;
}
.mt18 {
  margin-top: 18px;
}
.mt19 {
  margin-top: 19px;
}
.mt20 {
  margin-top: 20px;
}
.mt21 {
  margin-top: 21px;
}
.mt22 {
  margin-top: 22px;
}
.mt23 {
  margin-top: 23px;
}
.mt24 {
  margin-top: 24px;
}
.mt25 {
  margin-top: 25px;
}
.mt26 {
  margin-top: 26px;
}
.mt27 {
  margin-top: 27px;
}
.mt28 {
  margin-top: 28px;
}
.mt29 {
  margin-top: 29px;
}
.mt30 {
  margin-top: 30px;
}
.mt31 {
  margin-top: 31px;
}
.mt32 {
  margin-top: 32px;
}
.mt33 {
  margin-top: 33px;
}
.mt34 {
  margin-top: 34px;
}
.mt35 {
  margin-top: 35px;
}
.mt36 {
  margin-top: 36px;
}
.mt37 {
  margin-top: 37px;
}
.mt38 {
  margin-top: 38px;
}
.mt39 {
  margin-top: 39px;
}
.mt40 {
  margin-top: 40px;
}
.mt41 {
  margin-top: 41px;
}
.mt42 {
  margin-top: 42px;
}
.mt43 {
  margin-top: 43px;
}
.mt44 {
  margin-top: 44px;
}
.mt45 {
  margin-top: 45px;
}
.mt46 {
  margin-top: 46px;
}
.mt47 {
  margin-top: 47px;
}
.mt48 {
  margin-top: 48px;
}
.mt49 {
  margin-top: 49px;
}
.mt50 {
  margin-top: 50px;
}
.mt51 {
  margin-top: 51px;
}
.mt52 {
  margin-top: 52px;
}
.mt53 {
  margin-top: 53px;
}
.mt54 {
  margin-top: 54px;
}
.mt55 {
  margin-top: 55px;
}
.mt56 {
  margin-top: 56px;
}
.mt57 {
  margin-top: 57px;
}
.mt58 {
  margin-top: 58px;
}
.mt59 {
  margin-top: 59px;
}
.mt60 {
  margin-top: 60px;
}
.mt61 {
  margin-top: 61px;
}
.mt62 {
  margin-top: 62px;
}
.mt63 {
  margin-top: 63px;
}
.mt64 {
  margin-top: 64px;
}
.mt65 {
  margin-top: 65px;
}
.mt66 {
  margin-top: 66px;
}
.mt67 {
  margin-top: 67px;
}
.mt68 {
  margin-top: 68px;
}
.mt69 {
  margin-top: 69px;
}
.mt70 {
  margin-top: 70px;
}
.mt71 {
  margin-top: 71px;
}
.mt72 {
  margin-top: 72px;
}
.mt73 {
  margin-top: 73px;
}
.mt74 {
  margin-top: 74px;
}
.mt75 {
  margin-top: 75px;
}
.mt76 {
  margin-top: 76px;
}
.mt77 {
  margin-top: 77px;
}
.mt78 {
  margin-top: 78px;
}
.mt79 {
  margin-top: 79px;
}
.mt80 {
  margin-top: 80px;
}
.mt81 {
  margin-top: 81px;
}
.mt82 {
  margin-top: 82px;
}
.mt83 {
  margin-top: 83px;
}
.mt84 {
  margin-top: 84px;
}
.mt85 {
  margin-top: 85px;
}
.mt86 {
  margin-top: 86px;
}
.mt87 {
  margin-top: 87px;
}
.mt88 {
  margin-top: 88px;
}
.mt89 {
  margin-top: 89px;
}
.mt90 {
  margin-top: 90px;
}
.mt91 {
  margin-top: 91px;
}
.mt92 {
  margin-top: 92px;
}
.mt93 {
  margin-top: 93px;
}
.mt94 {
  margin-top: 94px;
}
.mt95 {
  margin-top: 95px;
}
.mt96 {
  margin-top: 96px;
}
.mt97 {
  margin-top: 97px;
}
.mt98 {
  margin-top: 98px;
}
.mt99 {
  margin-top: 99px;
}
.mt100 {
  margin-top: 100px;
}
.mt149 {
  margin-top: 149px;
}
.mt193 {
  margin-top: 193px;
}

// bottom
.mb0 {
  margin-bottom: 0px;
}
.mb1 {
  margin-bottom: 1px;
}
.mb2 {
  margin-bottom: 2px;
}
.mb3 {
  margin-bottom: 3px;
}
.mb4 {
  margin-bottom: 4px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb6 {
  margin-bottom: 6px;
}
.mb7 {
  margin-bottom: 7px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb9 {
  margin-bottom: 9px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb11 {
  margin-bottom: 11px;
}
.mb12 {
  margin-bottom: 12px;
}
.mb13 {
  margin-bottom: 13px;
}
.mb14 {
  margin-bottom: 14px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb17 {
  margin-bottom: 17px;
}
.mb18 {
  margin-bottom: 18px;
}
.mb19 {
  margin-bottom: 19px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb21 {
  margin-bottom: 21px;
}
.mb22 {
  margin-bottom: 22px;
}
.mb23 {
  margin-bottom: 23px;
}
.mb24 {
  margin-bottom: 24px;
}
.mb25 {
  margin-bottom: 25px;
}
.mb26 {
  margin-bottom: 26px;
}
.mb27 {
  margin-bottom: 27px;
}
.mb28 {
  margin-bottom: 28px;
}
.mb29 {
  margin-bottom: 29px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb31 {
  margin-bottom: 31px;
}
.mb32 {
  margin-bottom: 32px;
}
.mb33 {
  margin-bottom: 33px;
}
.mb34 {
  margin-bottom: 34px;
}
.mb35 {
  margin-bottom: 35px;
}
.mb36 {
  margin-bottom: 36px;
}
.mb37 {
  margin-bottom: 37px;
}
.mb38 {
  margin-bottom: 38px;
}
.mb39 {
  margin-bottom: 39px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb41 {
  margin-bottom: 41px;
}
.mb42 {
  margin-bottom: 42px;
}
.mb43 {
  margin-bottom: 43px;
}
.mb44 {
  margin-bottom: 44px;
}
.mb45 {
  margin-bottom: 45px;
}
.mb46 {
  margin-bottom: 46px;
}
.mb47 {
  margin-bottom: 47px;
}
.mb48 {
  margin-bottom: 48px;
}
.mb49 {
  margin-bottom: 49px;
}
.mb50 {
  margin-bottom: 50px;
}
.mb51 {
  margin-bottom: 51px;
}
.mb52 {
  margin-bottom: 52px;
}
.mb53 {
  margin-bottom: 53px;
}
.mb54 {
  margin-bottom: 54px;
}
.mb55 {
  margin-bottom: 55px;
}
.mb56 {
  margin-bottom: 56px;
}
.mb57 {
  margin-bottom: 57px;
}
.mb58 {
  margin-bottom: 58px;
}
.mb59 {
  margin-bottom: 59px;
}
.mb60 {
  margin-bottom: 60px;
}
.mb61 {
  margin-bottom: 61px;
}
.mb62 {
  margin-bottom: 62px;
}
.mb63 {
  margin-bottom: 63px;
}
.mb64 {
  margin-bottom: 64px;
}
.mb65 {
  margin-bottom: 65px;
}
.mb66 {
  margin-bottom: 66px;
}
.mb67 {
  margin-bottom: 67px;
}
.mb68 {
  margin-bottom: 68px;
}
.mb69 {
  margin-bottom: 69px;
}
.mb70 {
  margin-bottom: 70px;
}
.mb71 {
  margin-bottom: 71px;
}
.mb72 {
  margin-bottom: 72px;
}
.mb73 {
  margin-bottom: 73px;
}
.mb74 {
  margin-bottom: 74px;
}
.mb75 {
  margin-bottom: 75px;
}
.mb76 {
  margin-bottom: 76px;
}
.mb77 {
  margin-bottom: 77px;
}
.mb78 {
  margin-bottom: 78px;
}
.mb79 {
  margin-bottom: 79px;
}
.mb80 {
  margin-bottom: 80px;
}
.mb81 {
  margin-bottom: 81px;
}
.mb82 {
  margin-bottom: 82px;
}
.mb83 {
  margin-bottom: 83px;
}
.mb84 {
  margin-bottom: 84px;
}
.mb85 {
  margin-bottom: 85px;
}
.mb86 {
  margin-bottom: 86px;
}
.mb87 {
  margin-bottom: 87px;
}
.mb88 {
  margin-bottom: 88px;
}
.mb89 {
  margin-bottom: 89px;
}
.mb90 {
  margin-bottom: 90px;
}
.mb91 {
  margin-bottom: 91px;
}
.mb92 {
  margin-bottom: 92px;
}
.mb93 {
  margin-bottom: 93px;
}
.mb94 {
  margin-bottom: 94px;
}
.mb95 {
  margin-bottom: 95px;
}
.mb96 {
  margin-bottom: 96px;
}
.mb97 {
  margin-bottom: 97px;
}
.mb98 {
  margin-bottom: 98px;
}
.mb99 {
  margin-bottom: 99px;
}
.mb100 {
  margin-bottom: 100px;
}

// left
.ml-auto {
  margin-left: auto;
}
.ml0 {
  margin-left: 0px;
}
.ml1 {
  margin-left: 1px;
}
.ml2 {
  margin-left: 2px;
}
.ml3 {
  margin-left: 3px;
}
.ml4 {
  margin-left: 4px;
}
.ml5 {
  margin-left: 5px;
}
.ml6 {
  margin-left: 6px;
}
.ml7 {
  margin-left: 7px;
}
.ml8 {
  margin-left: 8px;
}
.ml9 {
  margin-left: 9px;
}
.ml10 {
  margin-left: 10px;
}
.ml11 {
  margin-left: 11px;
}
.ml12 {
  margin-left: 12px;
}
.ml13 {
  margin-left: 13px;
}
.ml14 {
  margin-left: 14px;
}
.ml15 {
  margin-left: 15px;
}
.ml16 {
  margin-left: 16px;
}
.ml17 {
  margin-left: 17px;
}
.ml18 {
  margin-left: 18px;
}
.ml19 {
  margin-left: 19px;
}
.ml20 {
  margin-left: 20px;
}
.ml21 {
  margin-left: 21px;
}
.ml22 {
  margin-left: 22px;
}
.ml23 {
  margin-left: 23px;
}
.ml24 {
  margin-left: 24px;
}
.ml25 {
  margin-left: 25px;
}
.ml26 {
  margin-left: 26px;
}
.ml27 {
  margin-left: 27px;
}
.ml28 {
  margin-left: 28px;
}
.ml29 {
  margin-left: 29px;
}
.ml30 {
  margin-left: 30px;
}
.ml31 {
  margin-left: 31px;
}
.ml32 {
  margin-left: 32px;
}
.ml33 {
  margin-left: 33px;
}
.ml34 {
  margin-left: 34px;
}
.ml35 {
  margin-left: 35px;
}
.ml36 {
  margin-left: 36px;
}
.ml37 {
  margin-left: 37px;
}
.ml38 {
  margin-left: 38px;
}
.ml39 {
  margin-left: 39px;
}
.ml40 {
  margin-left: 40px;
}
.ml41 {
  margin-left: 41px;
}
.ml42 {
  margin-left: 42px;
}
.ml43 {
  margin-left: 43px;
}
.ml44 {
  margin-left: 44px;
}
.ml45 {
  margin-left: 45px;
}
.ml46 {
  margin-left: 46px;
}
.ml47 {
  margin-left: 47px;
}
.ml48 {
  margin-left: 48px;
}
.ml49 {
  margin-left: 49px;
}
.ml50 {
  margin-left: 50px;
}
.ml51 {
  margin-left: 51px;
}
.ml52 {
  margin-left: 52px;
}
.ml53 {
  margin-left: 53px;
}
.ml54 {
  margin-left: 54px;
}
.ml55 {
  margin-left: 55px;
}
.ml56 {
  margin-left: 56px;
}
.ml57 {
  margin-left: 57px;
}
.ml58 {
  margin-left: 58px;
}
.ml59 {
  margin-left: 59px;
}
.ml60 {
  margin-left: 60px;
}
.ml61 {
  margin-left: 61px;
}
.ml62 {
  margin-left: 62px;
}
.ml63 {
  margin-left: 63px;
}
.ml64 {
  margin-left: 64px;
}
.ml65 {
  margin-left: 65px;
}
.ml66 {
  margin-left: 66px;
}
.ml67 {
  margin-left: 67px;
}
.ml68 {
  margin-left: 68px;
}
.ml69 {
  margin-left: 69px;
}
.ml70 {
  margin-left: 70px;
}
.ml71 {
  margin-left: 71px;
}
.ml72 {
  margin-left: 72px;
}
.ml73 {
  margin-left: 73px;
}
.ml74 {
  margin-left: 74px;
}
.ml75 {
  margin-left: 75px;
}
.ml76 {
  margin-left: 76px;
}
.ml77 {
  margin-left: 77px;
}
.ml78 {
  margin-left: 78px;
}
.ml79 {
  margin-left: 79px;
}
.ml80 {
  margin-left: 80px;
}
.ml81 {
  margin-left: 81px;
}
.ml82 {
  margin-left: 82px;
}
.ml83 {
  margin-left: 83px;
}
.ml84 {
  margin-left: 84px;
}
.ml85 {
  margin-left: 85px;
}
.ml86 {
  margin-left: 86px;
}
.ml87 {
  margin-left: 87px;
}
.ml88 {
  margin-left: 88px;
}
.ml89 {
  margin-left: 89px;
}
.ml90 {
  margin-left: 90px;
}
.ml91 {
  margin-left: 91px;
}
.ml92 {
  margin-left: 92px;
}
.ml93 {
  margin-left: 93px;
}
.ml94 {
  margin-left: 94px;
}
.ml95 {
  margin-left: 95px;
}
.ml96 {
  margin-left: 96px;
}
.ml97 {
  margin-left: 97px;
}
.ml98 {
  margin-left: 98px;
}
.ml99 {
  margin-left: 99px;
}
.ml100 {
  margin-left: 100px;
}

// right
.mr-auto {
  margin-right: auto;
}
.mr0 {
  margin-right: 0px;
}
.mr1 {
  margin-right: 1px;
}
.mr2 {
  margin-right: 2px;
}
.mr3 {
  margin-right: 3px;
}
.mr4 {
  margin-right: 4px;
}
.mr5 {
  margin-right: 5px;
}
.mr6 {
  margin-right: 6px;
}
.mr7 {
  margin-right: 7px;
}
.mr8 {
  margin-right: 8px;
}
.mr9 {
  margin-right: 9px;
}
.mr10 {
  margin-right: 10px;
}
.mr11 {
  margin-right: 11px;
}
.mr12 {
  margin-right: 12px;
}
.mr13 {
  margin-right: 13px;
}
.mr14 {
  margin-right: 14px;
}
.mr15 {
  margin-right: 15px;
}
.mr16 {
  margin-right: 16px;
}
.mr17 {
  margin-right: 17px;
}
.mr18 {
  margin-right: 18px;
}
.mr19 {
  margin-right: 19px;
}
.mr20 {
  margin-right: 20px;
}
.mr21 {
  margin-right: 21px;
}
.mr22 {
  margin-right: 22px;
}
.mr23 {
  margin-right: 23px;
}
.mr24 {
  margin-right: 24px;
}
.mr25 {
  margin-right: 25px;
}
.mr26 {
  margin-right: 26px;
}
.mr27 {
  margin-right: 27px;
}
.mr28 {
  margin-right: 28px;
}
.mr29 {
  margin-right: 29px;
}
.mr30 {
  margin-right: 30px;
}
.mr31 {
  margin-right: 31px;
}
.mr32 {
  margin-right: 32px;
}
.mr33 {
  margin-right: 33px;
}
.mr34 {
  margin-right: 34px;
}
.mr35 {
  margin-right: 35px;
}
.mr36 {
  margin-right: 36px;
}
.mr37 {
  margin-right: 37px;
}
.mr38 {
  margin-right: 38px;
}
.mr39 {
  margin-right: 39px;
}
.mr40 {
  margin-right: 40px;
}
.mr41 {
  margin-right: 41px;
}
.mr42 {
  margin-right: 42px;
}
.mr43 {
  margin-right: 43px;
}
.mr44 {
  margin-right: 44px;
}
.mr45 {
  margin-right: 45px;
}
.mr46 {
  margin-right: 46px;
}
.mr47 {
  margin-right: 47px;
}
.mr48 {
  margin-right: 48px;
}
.mr49 {
  margin-right: 49px;
}
.mr50 {
  margin-right: 50px;
}
.mr51 {
  margin-right: 51px;
}
.mr52 {
  margin-right: 52px;
}
.mr53 {
  margin-right: 53px;
}
.mr54 {
  margin-right: 54px;
}
.mr55 {
  margin-right: 55px;
}
.mr56 {
  margin-right: 56px;
}
.mr57 {
  margin-right: 57px;
}
.mr58 {
  margin-right: 58px;
}
.mr59 {
  margin-right: 59px;
}
.mr60 {
  margin-right: 60px;
}
.mr61 {
  margin-right: 61px;
}
.mr62 {
  margin-right: 62px;
}
.mr63 {
  margin-right: 63px;
}
.mr64 {
  margin-right: 64px;
}
.mr65 {
  margin-right: 65px;
}
.mr66 {
  margin-right: 66px;
}
.mr67 {
  margin-right: 67px;
}
.mr68 {
  margin-right: 68px;
}
.mr69 {
  margin-right: 69px;
}
.mr70 {
  margin-right: 70px;
}
.mr71 {
  margin-right: 71px;
}
.mr72 {
  margin-right: 72px;
}
.mr73 {
  margin-right: 73px;
}
.mr74 {
  margin-right: 74px;
}
.mr75 {
  margin-right: 75px;
}
.mr76 {
  margin-right: 76px;
}
.mr77 {
  margin-right: 77px;
}
.mr78 {
  margin-right: 78px;
}
.mr79 {
  margin-right: 79px;
}
.mr80 {
  margin-right: 80px;
}
.mr81 {
  margin-right: 81px;
}
.mr82 {
  margin-right: 82px;
}
.mr83 {
  margin-right: 83px;
}
.mr84 {
  margin-right: 84px;
}
.mr85 {
  margin-right: 85px;
}
.mr86 {
  margin-right: 86px;
}
.mr87 {
  margin-right: 87px;
}
.mr88 {
  margin-right: 88px;
}
.mr89 {
  margin-right: 89px;
}
.mr90 {
  margin-right: 90px;
}
.mr91 {
  margin-right: 91px;
}
.mr92 {
  margin-right: 92px;
}
.mr93 {
  margin-right: 93px;
}
.mr94 {
  margin-right: 94px;
}
.mr95 {
  margin-right: 95px;
}
.mr96 {
  margin-right: 96px;
}
.mr97 {
  margin-right: 97px;
}
.mr98 {
  margin-right: 98px;
}
.mr99 {
  margin-right: 99px;
}
.mr100 {
  margin-right: 100px;
}
