.phones-table {
  .ant-table {
    tr {
      th:nth-last-of-type(1),
      th:nth-last-of-type(2) {
        border-left: none;
      }
      td:nth-last-of-type(1) {
        border-left: none;
      }
    }
  }
  &__dscTextArea {
    vertical-align: top;
    width: 100%;
    min-height: 95px;

    textarea.ant-input {
      height: 64px;
      max-height: 300px;
    }
  }
  &__addPhoneBtn {
    width: 216px;

    svg {
      margin-top: 1px;
    }
    span {
      position: relative;
      bottom: 7px;
      margin-left: 10px;
    }
  }
}

@primary-color: #1DA57A;