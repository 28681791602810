@import '../../style/constantes.less';

.administration {
  &-navbar {
    margin-left: 20px;
    margin-right: 20px;
    &-menu {
      display: flex;
      &-item {
        height: 28px;
        background-color: @substrate-color;
        font-family: @font-roboto;
        font-size: 14px;
        text-align: center;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding-top: 4px;
        margin-right: 9px;
        cursor: pointer;
      }
    }
  }
  &-personList {
    &-addBtn {
      width: 216px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &-searchBtn {
      margin-top: 10px;
      min-width: 110px !important;
    }
    &-password-rules {
      text-align: left;
      padding-left: 17px;
      margin-bottom: 0;
      li {
        list-style-type: none;
      }
      li::before {
        content: '•';
        color: #315373;
        display: inline-block;
        width: 16px;
        margin-left: -18px;
      }
    }
  }
}

@primary-color: #1DA57A;