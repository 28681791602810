.administrationPerson {
  &-profile {
    .ant-btn {
      width: 216px;
    }
    .ant-form-item {
      label {
        padding-left: 0;
      }
    }
  }
  &-roles {
    .ant-table {
      tr {
        th:nth-of-type(2),
        td:nth-of-type(2) {
          border-left: none;
        }
      }
    }
    &-checkAll {
      display: flex;
      margin-bottom: 3px;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
      div {
        line-height: 25px;
      }
    }
  }
}

@primary-color: #1DA57A;