.comment {
  .info-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .text {
    border: 1px solid #9d9d9d;
    border-radius: 10px;
    padding: 9px;
    margin-top: 6px;
  }
}

@primary-color: #1DA57A;