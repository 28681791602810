.file-comments {
  .comments-container {
    padding: 10px 30px;
    background-color: white;
  }

  .divider {
    width: 30%;
  }
}

@primary-color: #1DA57A;