@import '../../style/constantes.less';

.info {
  &-main {
    display: flex;
    padding-top: 20px;
    background-color: white;
    padding-bottom: 330px;

    .ant-btn {
      width: 216px;
    }
  }
  &-navbar {
    background-color: white;
    width: 236px;
    min-width: 236px;
    &-menu {
      &-item {
        height: 28px;
        background-color: @substrate-color;
        font-family: @font-roboto;
        font-size: 14px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-top: 4px;
        padding-left: 25px;
        margin-bottom: 10px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &-right-bar {
    background-color: white;
    width: 236px;
    min-width: 236px;
  }
}

@primary-color: #1DA57A;