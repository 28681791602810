@import '../../style/constantes.less';

.create-application {
  .ant-btn {
    width: 216px;
  }
  &__dscTextArea {
    height: 135px;
    width: 100%;
    textarea.ant-input {
      height: 105px;
    }
  }
  &__searchPerson {
    table {
      // Удаление лишний вертикальных разделителей
      tr {
        th:nth-last-of-type(1),
        th:nth-of-type(2),
        th:nth-of-type(3),
        td:nth-last-of-type(1),
        td:nth-of-type(2),
        td:nth-of-type(3) {
          border-left: none;
        }
      }
    }
    .inPrayListIcon {
      position: relative;
      top: 3px;
    }
  }
  &__addAddressBtn {
    width: 216px;

    svg {
      margin-top: 1px;
    }
    span {
      position: relative;
      bottom: 7px;
      margin-left: 10px;
    }
  }
}

@primary-color: #1DA57A;