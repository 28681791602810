.card {
  // white-space: pre;

  .info-row {
    display: flex;

    .info {
      white-space: normal;
      word-break: break-all;
    }
  }

  .block {
    background-color: white;
    height: 100%;
    border-radius: 4px;
    padding: 10px;
  }

  .ant-divider {
    margin: 10px 0 !important;
  }

  .row-value {
    width: 170px;
    white-space: pre-wrap;
    word-break: break-all;
  }

  .row-value-ellipsis {
    white-space: pre-wrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  h3 {
    font-weight: bold;
  }

  input {
    width: 453px;
  }

  textarea {
    width: 453px;
  }

  .card-select {
    width: 453px;
  }
}

@primary-color: #1DA57A;