.list {
  background-color: white;
  height: 594px;

  .row {
    padding: 8px 10px;
    min-height: 41.14px;
  }

  .even-row {
    background-color: #f4f4f4;
  }

  .list-rows {
    overflow-y: auto;
    height: 530px;
  }
}

@primary-color: #1DA57A;