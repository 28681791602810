@import '../../style/constantes.less';

.password-recovery {
  &-container {
    height: 725px;
    width: 690px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: calc((90vh - 633px) / 2);
    background-color: @substrate-color;
    border-radius: 20px;
    overflow: hidden;
  }
  &-innerContainer {
    width: 454px;
    height: 645x;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  &-submitBtn.ant-btn {
    width: 216px;
  }
  &-info-modal {
    .anticon-check-circle {
      display: none;
    }
  }
  &-password {
    height: 76px;
    margin-bottom: 0 !important;
    .ant-form-item-explain {
      padding-top: 5px;
    }
  }
}

@primary-color: #1DA57A;