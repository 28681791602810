@import '~antd/dist/antd.less';
@import './constantes.less';

// Переопределение дефолтных стилей antd
@body-background: #d0d8da;
@font-family: @font-ptSerif;
@font-size-base: 16px;
@heading-color: #48433f;
@text-color: @dark-gray-color;
@link-color: @middle-gray-color;
// Button
@btn-primary-bg: @middle-gray-color;
@btn-border-radius-base: 20px;
@btn-height-base: 36px;
@btn-ghost-color: @blured-white-color;
@btn-ghost-bg: @dark-blue-color;
// Form
@label-color: @dark-gray-color;
@form-vertical-label-padding: 0 0 3px;
@form-item-margin-bottom: 10px;
// Divider
@divider-color: @middle-gray-color;
// Collapse
@collapse-header-padding: @padding-sm 20px;
@collapse-content-padding: @padding-md 20px;
// Table
@table-header-bg: transparent;
@table-header-cell-split-color: transparent;
// Pagination
@pagination-font-weight-active: bold;
// Checkbox
@checkbox-color: @middle-gray-color;

a:hover {
  color: @middle-gray-color !important;
}

.ant-btn {
  min-width: 150px;
}

// Стили модалки
.ant-modal {
  border-radius: 20px;

  .ant-modal-content {
    border-radius: 20px;

    .ant-modal-body {
      border-radius: 20px;
    }
  }
}

// Table
.ant-table {
  tr {
    .ant-table-cell {
      color: @dark-gray-color;
      font-family: @font-roboto;
      font-size: 12px;
    }

    th.ant-table-cell {
      font-weight: bold;
    }
  }
}

// Pagination
.ant-pagination-item {
  border: none;

  &.ant-pagination-item-active {
    a {
      color: @dark-gray-color;
    }
  }
}

// Form.Item: Перенос звездочки обязательности вправо
.ant-form-item-label {
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
    &::before {
      display: none;
    }

    &::after {
      display: inline-block;
      margin-left: 4px;
      color: #ff4d4f;
      font-size: 16px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
}

// Form.Item: Позиционирование уведомления об ошибке
.ant-form-item-explain-error {
  text-align: left;
  padding-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

// Form.Item
.ant-form-item-control {
  //height: 32px;
  .ant-input,
  .ant-input-password,
  .ant-select > .ant-select-selector,
  .ant-select > .ant-select-selector > .ant-select-selection-search,
  .ant-select > .ant-select-selector > .ant-select-selection-placeholder,
  .ant-select > .ant-select-selector > .ant-select-selection-search > .ant-select-selection-search-input,
  .ant-picker,
  .ant-picker-input > input,
  .ant-input-affix-wrapper {
    text-align: left;
    //height: 32px;
    border-radius: 10px;
    font-family: @font-roboto;
    font-size: 14px;

    .ant-input {
      height: 24px;
    }
  }
}

// Select with mode "multiple": выравнивание плейсхолдера
.ant-select > .ant-select-selector > .ant-select-selection-overflow + span {
  // padding-top: 5px;
}

// Form.Item. Класс для обертки с dependencies или shouldUpdate
.form-item-dependencies,
.form-item-height-auto {
  margin-bottom: 0;

  .ant-form-item-control {
    height: auto;

    .ant-form-item-explain {
      display: none;
    }
  }
}

// Select: позиционирование плейсхолдера
.ant-select-selection-placeholder {
  text-align: left;
}

// Divider
.ant-divider-horizontal {
  border-top-width: medium;
  border-radius: 2px;
  margin: 20px 0;

  &.thin-divider {
    border-top-width: thin;
  }
}

// Стилизация для antd-mask-input
.ant-input-affix-wrapper {
  height: 32px;
}

// Стилизация ошибки для antd-mask-input
.ant-form-item-has-error {
  margin-bottom: @form-item-margin-bottom;

  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper:hover {
    border-color: @red-color;
  }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.2);
  }
}

// RadioGroup: шрифт ярлыка
.ant-radio-group {
  text-align: left;
  padding-left: @label-padding-left;
  display: block;

  .ant-radio-wrapper {
    span {
      font-size: 14px;
    }
  }
}

// Checkbox: шрифт ярлыка
.ant-checkbox-wrapper {
  span {
    //font-size: 14px;
  }
}

// Collapse: отступ между title и контентом
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0;
}

// Input: стиль префикса
.ant-input-prefix {
  color: #bfbfbf;
  font-size: 14px;
  font-family: @font-roboto;
}

// Класс для снятия hover со строк таблицы
.table {
  &__no-hover {
    .ant-table-tbody {
      tr.ant-table-row:hover {
        td {
          background: unset;
        }
      }
    }
  }
}

// Стиль для скрытия валидационного уведомления в From.Item c сохранением отступов
.form-item-required__hidden-error.ant-form-item {
  .ant-form-item-explain {
    display: none;
  }
}

// Стиль для скрытия валидационного уведомления в From.Item без сохранения отступов
.form-item-required__hidden-error__mb0.ant-form-item {
  margin-bottom: 0;

  .ant-form-item-explain {
    display: none;
  }

  .ant-form-item-control-input {
    min-height: auto;
  }
}

// Стиль для корректного отображения сообщения об ошибке
.form-item-required__error {
  height: 80px;
  margin-bottom: 0;
  .ant-form-item-explain-error {
    padding-top: 5px;
  }
}

// Стили для Modal.error
.ant-modal-confirm-error {
  .ant-modal-body {
    background-color: #f4f4f4;
  }
  .ant-modal-confirm-btns {
    display: flex;
    justify-content: center;
  }
}

// Стили для message
.ant-message-notice-content {
  border-radius: 10px;
}

// Замена стрелки в RangePicker, т.к. оригинальная с ошибкой
.ant-picker-range {
  svg[data-icon='swap-right'] {
    path {
      display: none;
    }

    background-image: url('../lib/img/picker-arrow.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}
