@dark-gray-color: #2a2a2a;
.dark-gray-color {
  color: @dark-gray-color;
}

@middle-gray-color: #7590a3;
.middle-gray-color {
  color: @middle-gray-color;
}

@light-gray-color: #9d9d9d;
.light-gray-color {
  color: @light-gray-color;
}

@dark-blue-color: #315372;
.dark-blue-color {
  color: @dark-blue-color;
}

@blured-white-color: #ffffffcc;
.blured-white-color {
  color: @blured-white-color;
}

@substrate-color: #f4f4f4;
.substrate-color {
  color: @substrate-color;
}

@red-color: #ee2d24;
.red-color {
  color: @red-color;
}

@font-roboto: Roboto, sans-serif;
@font-ptSerif: 'PT Serif';
.font-ptSerif {
  font-family: @font-ptSerif;
}

@label-padding-left: 5px;
