@import '../../style/constantes.less';

.administration-dictionaries {
  .ant-btn {
    width: 216px;
  }

  &-navbar {
    margin-left: 20px;
    margin-right: 20px;
    &-menu {
      display: flex;
      &-item {
        //height: 10;
        height: 28px;
        background-color: @substrate-color;
        font-family: @font-roboto;
        font-size: 14px;
        text-align: center;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding-top: 4px;
        margin-right: 9px;
        cursor: pointer;
      }
    }
  }

  .ant-table {
    tr {
      th:nth-of-type(3),
      td:nth-of-type(3) {
        border-left: none;
      }
    }

    .archive {
      td {
        color: #9d9d9d;
      }
    }

    .editing {
      background-color: #d8e6df;
    }
  }

  .ant-input-affix-wrapper,
  .ant-input {
    text-align: left;
    border-radius: 10px !important;
    font-family: @font-roboto;
    font-size: 14px;
  }

  .ant-select-selector,
  .ant-select {
    text-align: left;
    border-radius: 10px !important;
    font-family: @font-roboto;
    font-size: 14px;

    .ant-select-clear {
      width: 20px;
      right: 13px;
    }
  }
}

@primary-color: #1DA57A;