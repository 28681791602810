//:not(.ymaps-2-1-79-b-cluster-tabs) {
//
//}

//.ymaps-2-1-79-balloon {
//  border-radius: 10px !important;
//
//  .ymaps-2-1-79-balloon__layout {
//    border-radius: 10px !important;
//  }
//
//  .ymaps-2-1-79-balloon__content {
//    padding: 0 !important;
//    margin-right: 0 !important;
//    border-radius: 10px !important;
//  }
//
//  .ymaps-2-1-79-balloon__tail {
//    background-color: #7CC6FC !important;
//
//    &:after {
//      background-color: #7CC6FC !important;
//    }
//  }
//}

.ymaps-2-1-79-b-cluster-tabs__content-item {
  display: flex !important;
  flex-direction: column;
  height: 100%;

  .ymaps-2-1-79-b-cluster-content__body {
    flex-grow: 1;
  }
}

.ymaps-2-1-79-balloon__content {
  padding: 0 !important;
  margin: 0 !important;

  .ymaps-2-1-79-balloon-content__header {
    background-color: #f4f4f4;
    padding: 10px;

    .YandexPersonHeader {
      font-size: 14px !important;
    }
  }

  .my-ballon__body {
    padding: 10px;
  }

  .ymaps-2-1-79-balloon-content__footer {
    background-color: #7cc6fc;
    padding: 10px;
  }
}

.ymaps-2-1-79-balloon__tail {
  &:after {
    background-color: #7cc6fc !important;
  }

  background-color: #7cc6fc !important;
}

.ymaps-2-1-79-b-cluster-content__header {
  padding: 10px !important;

  .YandexPersonHeader {
    font-size: 14px !important;
  }
}

.ymaps-2-1-79-b-cluster-content__footer {
  padding: 10px !important;
}

.ymaps-2-1-79-balloon {
  border-radius: 10px !important;

  .ymaps-2-1-79-balloon__layout {
    border-radius: 10px !important;
  }

  .ymaps-2-1-79-balloon__content {
    border-radius: 10px !important;
  }
}

.ymaps-2-1-79-b-cluster-tabs__menu {
  background-color: #7cc6fc !important;
  // overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.ymaps-2-1-79-b-cluster-tabs__section_type_nav {
  width: auto !important;
  max-width: 220px !important;
}

.ymaps-2-1-79-b-cluster-tabs__menu-item {
  color: black;
  word-break: break-word;
  max-height: 28px;
  min-height: 28px;

  .NotCustomYandexHeader {
    height: 28px;

    a {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: fit-content;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

.ymaps-2-1-79-b-cluster-tabs__menu-item-text {
  pointer-events: none;
  color: black;
  div {
    pointer-events: none;
    color: black;
  }
  a {
    color: black;
    text-decoration: none !important;
  }
  min-height: 28px;
  width: 100%;
}

.ymaps-2-1-79-b-cluster-tabs__menu-item_current_yes {
  background-color: white !important;
}

.ymaps-2-1-79-b-cluster-tabs {
  .ymaps-2-1-79-b-cluster-tabs__section_type_nav {
    cursor: auto;
  }
  .ymaps-2-1-79-b-cluster-tabs__section_type_content {
    cursor: pointer;
    height: 100%;
  }
}
.ymaps-2-1-79-balloon__content {
  cursor: pointer;
  height: 100%;
  a {
    pointer-events: none !important;
  }
}

// Убираем иконку в открытом кластере у выбранной метки
.ymaps-2-1-79-b-cluster-tabs__section {
  .ymaps-2-1-79-b-cluster-content__header {
    .NotCustomYandexHeader {
      div {
        display: none;
      }
      a {
        margin-left: 0;
      }
    }
  }
}

@primary-color: #1DA57A;