@import '../../style/constantes.less';

.application {
  .ant-btn {
    width: 216px;
  }
  &-navbar {
    margin-left: 20px;
    margin-right: 20px;
    &-menu {
      display: flex;
      &-item {
        //height: 10;
        height: 28px;
        background-color: @substrate-color;
        font-family: @font-roboto;
        font-size: 14px;
        text-align: center;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding-top: 4px;
        margin-right: 9px;
        cursor: pointer;
      }
    }
  }
  &-main {
    background-color: white;
    &__dataLabel {
      font-family: @font-roboto;
      line-height: 29px;
    }
    &__dataValue {
      font-family: @font-roboto;
      font-size: 14px;
      line-height: 29px;
      padding-left: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__dscTextArea {
      height: 94px;
      width: 100%;
      textarea.ant-input {
        height: 64px;
      }
    }
  }
  &-executors {
    background-color: white;
    padding: 20px;
    padding-top: 10px;
    height: 650px;
    &__loupeIcon {
      position: relative;
      top: -32px;
      left: 89%;
      // z-index: 100;
    }
    .ant-table {
      tr {
        th:nth-of-type(2),
        td:nth-of-type(2) {
          border-left: none;
        }
      }
    }
    &__search.ant-form-item {
      height: 32px;
      margin-bottom: 3px;
    }
  }
  &-comments {
    background-color: white;
    padding: 20px;
    min-height: 666px;
    &__textArea {
      .ant-form-item-control {
        height: 64px;
        textarea.ant-input {
          height: 64px;
        }
      }
    }
    &__dataLabel,
    &__createDate,
    &__commentText {
      line-height: 30px;
      font-family: @font-roboto;
      font-size: 12px;
    }
    &__commentText {
      border: 1px solid #9d9d9d66;
      border-radius: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  &-history {
    background-color: white;
    padding-bottom: 20px;
    min-height: 666px;
  }
  &-responseHistory {
    background-color: white;
    padding: 20px;
    padding-top: 10px;
    height: 650px;
  }
  .ant-table tr .application-history__firstColumn {
    padding-left: 20px !important;
  }
  .ant-table tr .application-history__lastColumn {
    padding-right: 20px !important;
  }
}

@primary-color: #1DA57A;