@import '../../style/constantes.less';

.user-profile {
  h2,
  h3 {
    font-weight: bold;
  }

  .ant-btn {
    width: 216px;
  }
  .ant-divider {
    margin: 10px 0 !important;
  }

  .title {
    text-align: start;
  }

  .info-column {
    display: flex;
    flex-direction: column;
  }

  .label_item {
    font-family: @font-roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
  }

  .fullName_item {
    width: fit-content;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
  }

  .info_item {
    font-family: @font-roboto;
    width: fit-content;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .ant-collapse-header {
    text-align: start;
    font-family: @font-roboto;
    font-size: 14px;
    font-weight: 700;
    padding-left: 4px !important;
  }

  .subscription-list-form {
    .ant-collapse-item {
      .ant-form-item-row {
        width: 100%;
      }
    }

    .rotate {
      transform: rotate(180deg);
    }
  }

  .ant-collapse-content-box {
    text-align: start;
    padding-left: 5px !important;

    .subscription-list-item {
      font-size: 12px !important;
      font-family: @font-roboto;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 9px;
    }
  }
}

@primary-color: #1DA57A;