@import './constantes.less';

.error {
  color: @red-color;
}

.text-bold {
  font-weight: bold;
}

.text-underline {
  text-decoration: underline;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-12 {
  font-size: 12px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.font-roboto {
  font-family: @font-roboto;
}
