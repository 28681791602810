.file-projects {
  .block {
    background-color: white;
    height: 100%;
    border-radius: 4px;
    padding: 10px;

    h3 {
      font-weight: bold;
    }
  }

  .divider {
    width: 30%;
    .ant-divider {
      margin: 10px 0 !important;
    }
  }
}

@primary-color: #1DA57A;