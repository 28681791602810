@import './antd.less';
@import './constantes.less';
@import './margins.less';
@import './paddings.less';
@import './text.less';
@import './display.less';
@import './cursor.less';

body {
  margin: 0;
  height: 0;
  h1 {
    font-size: 48px;
    margin-bottom: 0;
    line-height: 55px;
  }
  h3 {
    font-size: 18px;
  }
  // // Запрет выделения текста
  // -moz-user-select: none;
  // -khtml-user-select: none;
  // user-select: none;
}

.container {
  min-height: 90vh;
  min-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  text-align: -webkit-center;

  &_height {
    min-height: 90vh;
    min-width: 1240px;
    margin-left: auto;
    margin-right: auto;
    text-align: -webkit-center;
  }
}

.logo {
  width: 120px;
  height: 120px;
  background: url('.././lib/img/logo.svg') no-repeat;
  margin-left: auto;
  margin-right: auto;
}

.logo-small {
  width: 42px;
  height: 42px;
  background: url('.././lib/img/logo-small.svg') no-repeat;
}

label,
.label-style {
  font-family: Roboto, sans-serif;
  padding-left: @label-padding-left;
  text-align: left;
}

// Плейсхолдеры элементов
/* Chrome, Firefox, Opera, Safari 10.1+ */
::placeholder {
  font-family: @font-roboto !important;
  font-size: 14px;
  opacity: 1 !important; /* Firefox */
}
/* Internet Explorer 10-11 */
:-ms-input-placeholder {
  font-size: 14px;
  font-family: @font-roboto !important;
}
/* Microsoft Edge */
::-ms-input-placeholder {
  font-size: 14px;
  font-family: @font-roboto !important;
}
.ant-select-selection-placeholder {
  font-size: 14px;
  font-family: @font-roboto !important;
}

.button-icon.ant-btn {
  min-width: 24px;
  width: 24px;
  height: 24px;
  border: none;
  padding: 0;
}

// Полосатая таблица
// Указать таблице класс и передать rowClassName={(r, i) => (i % 2 ? "" : "striped-table__oddRow")}
.striped-table {
  max-width: 99.9%;
  .ant-table {
    font-family: @font-roboto;
    tr {
      height: 30px;
      font-size: 12px;
      th {
        font-weight: bold;
        border-left: 1px solid #e7e7e7;
        padding: 0 0 0 10px !important;
      }
      td {
        border-left: 1px solid #e7e7e7;
        padding: 0 0 0 10px !important;
      }
      th:nth-of-type(1) {
        border-left: none;
      }
      td:nth-of-type(1) {
        border-left: none;
      }
    }
  }
  &__oddRow {
    background-color: #f4f4f4;
  }
}

.bordered-table {
  border: 1px solid @middle-gray-color;
}

// У всех select с icons убрать иконки в нутри input
.ant-form-item-control-input {
  .icon-application-select {
    display: none;
  }
  .ant-select-item-option-content {
    margin-left: 0px !important;
  }
}

.ant-imput-for-mask {
  input {
    padding-left: 1px !important;
  }
}

@primary-color: #1DA57A;