@import '../../style/constantes.less';

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0;

  &-container {
    padding: 40px;
    width: 90%;
    max-width: 690px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: @substrate-color;
    border-radius: 20px;
    overflow: hidden;
    // margin-left: auto;
    // margin-right: auto;
    // margin-top: calc((90vh - 633px) / 2);
    margin: 10px auto; /* Горизонтальная центровка */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Тень для эффекта */

    .company-row {
      display: flex;
      justify-content: center;
    }

    .company-title {
      font-size: 14px;
      font-weight: 700;
    }

    .company-logo {
      display: flex;
      font-weight: 700;
      font-size: 14px;
    }

    .logo {
      width: 120px;
      height: 120px;
      transition: font-size 0.5s ease;
    }

    @media (max-width: 499px) {
      .logo {
        width: 100px;
        height: 100px;
        background: url('../../lib/img/logo-middle.svg') no-repeat;
      }
    }

    .title {
      font-size: 48px;
      transition: font-size 0.5s ease;
    }

    @media (min-width: 500px) {
      .title {
        font-size: 48px;
      }
    }

    @media (max-width: 499px) {
      .title {
        font-size: 32px;
      }
    }

    .containerLogo {
      display: grid;
      gap: 10px !important;
    }

    @media (min-width: 651px) {
      .containerLogo {
        grid-template-columns: repeat(3, 1fr); /* 3 колонки при ширине экрана >= 600px */
      }
    }

    @media (max-width: 650px) {
      .containerLogo {
        grid-template-columns: 1fr; /* 1 колонка при ширине экрана < 600px */
      }
    }
  }
  &-innerContainer {
    width: 100%;
    max-width: 454px;
    // height: 513px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  &-header {
    display: block;
    margin: 10px auto 0;
  }
  &-infoBox {
    display: flex;
    margin-top: -7px;
    justify-content: space-between;
  }
}

@primary-color: #1DA57A;