.user-form-component {
  // white-space: pre;

  .block {
    background-color: white;
    height: 100%;
    border-radius: 4px;
    padding: 10px;

    .ant-form-item-label > label {
      padding-left: 0;
    }
    // Обруч default
    .ant-radio-checked .ant-radio-inner {
      border-color: #2a2a2a;
    }
    // Обруч при наведении
    .ant-radio:hover .ant-radio-inner {
      border-color: #2a2a2a;
    }
    // Точка внутри при выборе
    .ant-radio-checked .ant-radio-inner:after {
      background-color: #2a2a2a;
      transform: scale(0.3);
      color: #2a2a2a;
    }

    // Изменяем цвет тени на чёрно-серый
    .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: 0 0 0 3px rgba(52, 47, 47, 0.12);
    }
    // Убираем анимацию при клике
    .ant-radio {
      &::after {
        all: unset;
      }
    }

    .ant-input {
      height: 32px;
    }
  }

  .ant-divider {
    margin: 10px 0 !important;
  }

  .row-value {
    width: 170px;
    white-space: pre-wrap;
  }

  .row-value-break-word {
    width: 220px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  h3 {
    font-weight: bold;
  }

  input {
    width: 453px;
    max-width: 100%;
  }

  textarea {
    width: 453px !important;
    max-width: 100%;
  }

  .card-select {
    width: 453px !important;
    max-width: 100%;
  }

  .mask-input {
    width: 453px;
    max-width: 100%;
  }

  span:has(#suggest) {
    width: 453px !important;
    max-width: 100%;
  }

  .ant-select-selector:has(#regionId) {
    width: 453px !important;
    max-width: 100%;
  }

  .ant-form-item,
  .ant-input {
    max-width: 100% !important;
  }
}

@primary-color: #1DA57A;