@import '../../style/constantes.less';

.create-user {
  &-container {
    width: 1164px;
    min-height: 1430px;
    justify-content: center;
    display: flex;
    // flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 36px;
    background-color: @substrate-color;
    border-radius: 20px;
    overflow: hidden;
  }

  &-innerContainer {
    width: 926px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.password-list {
  text-align: left;
  padding-left: 17px;

  li::before {
    content: '•';
    color: #315373;
    display: inline-block;
    width: 2em;
    margin-left: -1em;
  }
}

@primary-color: #1DA57A;