@import '../../style/constantes.less';

.applications-list {
  background-color: white;
  .ant-btn {
    width: 216px;
  }
  &-header {
    display: flex;
    max-height: 76px;

    .active-donate {
      background: #7590a3;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border: none;
    }

    .active-donate:focus {
      background: #7590a3;
    }

    .not-active-donate {
      background: #f4f4f4;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border: none;
    }

    .not-active-donate:focus {
      background: #f4f4f4;
    }
  }
  &-menu {
    display: flex;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
    border-radius: 18px;
    height: 36px;
    &-item {
      width: 110px;
      background-color: @substrate-color;
      font-family: @font-roboto;
      font-size: 14px;
      text-align: center;
      padding-top: 7px;
      cursor: pointer;

      &-left {
        border-radius: 18px 0 0 18px;
      }
      &-right {
        border-radius: 0 18px 18px 0;
      }
    }
  }

  &-search {
    .ant-btn {
      font-family: 'PT Serif';
      font-size: 14px;
      height: 32px;
      width: 130px;
    }
    &-textArea.ant-form-item {
      height: 74px;
      .ant-form-item-control {
        height: 74px;
        textarea.ant-input {
          height: 74px;
        }
      }
    }
    .ant-picker-input {
      input {
        font-size: 14px;
      }
    }
  }
  .app-text-no-wrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .app-text-no-wrap-100 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
  }
  .width-24 {
    min-width: 24px !important;
    max-width: 24px !important;
  }
}

@primary-color: #1DA57A;