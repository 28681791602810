@import './constantes.less';

// top
.pt0 {
  padding-top: 0px;
}
.pt1 {
  padding-top: 1px;
}
.pt2 {
  padding-top: 2px;
}
.pt3 {
  padding-top: 3px;
}
.pt4 {
  padding-top: 4px;
}
.pt5 {
  padding-top: 5px;
}
.pt6 {
  padding-top: 6px;
}
.pt7 {
  padding-top: 7px;
}
.pt8 {
  padding-top: 8px;
}
.pt9 {
  padding-top: 9px;
}
.pt10 {
  padding-top: 10px;
}
.pt11 {
  padding-top: 11px;
}
.pt12 {
  padding-top: 12px;
}
.pt13 {
  padding-top: 13px;
}
.pt14 {
  padding-top: 14px;
}
.pt15 {
  padding-top: 15px;
}
.pt16 {
  padding-top: 16px;
}
.pt17 {
  padding-top: 17px;
}
.pt18 {
  padding-top: 18px;
}
.pt19 {
  padding-top: 19px;
}
.pt20 {
  padding-top: 20px;
}
.pt21 {
  padding-top: 21px;
}
.pt22 {
  padding-top: 22px;
}
.pt23 {
  padding-top: 23px;
}
.pt24 {
  padding-top: 24px;
}
.pt25 {
  padding-top: 25px;
}
.pt26 {
  padding-top: 26px;
}
.pt27 {
  padding-top: 27px;
}
.pt28 {
  padding-top: 28px;
}
.pt29 {
  padding-top: 29px;
}
.pt30 {
  padding-top: 30px;
}
.pt31 {
  padding-top: 31px;
}
.pt32 {
  padding-top: 32px;
}
.pt33 {
  padding-top: 33px;
}
.pt34 {
  padding-top: 34px;
}
.pt35 {
  padding-top: 35px;
}
.pt36 {
  padding-top: 36px;
}
.pt37 {
  padding-top: 37px;
}
.pt38 {
  padding-top: 38px;
}
.pt39 {
  padding-top: 39px;
}
.pt40 {
  padding-top: 40px;
}
.pt41 {
  padding-top: 41px;
}
.pt42 {
  padding-top: 42px;
}
.pt43 {
  padding-top: 43px;
}
.pt44 {
  padding-top: 44px;
}
.pt45 {
  padding-top: 45px;
}
.pt46 {
  padding-top: 46px;
}
.pt47 {
  padding-top: 47px;
}
.pt48 {
  padding-top: 48px;
}
.pt49 {
  padding-top: 49px;
}
.pt50 {
  padding-top: 50px;
}

// bottom
.pb0 {
  padding-bottom: 0px;
}
.pb1 {
  padding-bottom: 1px;
}
.pb2 {
  padding-bottom: 2px;
}
.pb3 {
  padding-bottom: 3px;
}
.pb4 {
  padding-bottom: 4px;
}
.pb5 {
  padding-bottom: 5px;
}
.pb6 {
  padding-bottom: 6px;
}
.pb7 {
  padding-bottom: 7px;
}
.pb8 {
  padding-bottom: 8px;
}
.pb9 {
  padding-bottom: 9px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb11 {
  padding-bottom: 11px;
}
.pb12 {
  padding-bottom: 12px;
}
.pb13 {
  padding-bottom: 13px;
}
.pb14 {
  padding-bottom: 14px;
}
.pb15 {
  padding-bottom: 15px;
}
.pb16 {
  padding-bottom: 16px;
}
.pb17 {
  padding-bottom: 17px;
}
.pb18 {
  padding-bottom: 18px;
}
.pb19 {
  padding-bottom: 19px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb21 {
  padding-bottom: 21px;
}
.pb22 {
  padding-bottom: 22px;
}
.pb23 {
  padding-bottom: 23px;
}
.pb24 {
  padding-bottom: 24px;
}
.pb25 {
  padding-bottom: 25px;
}
.pb26 {
  padding-bottom: 26px;
}
.pb27 {
  padding-bottom: 27px;
}
.pb28 {
  padding-bottom: 28px;
}
.pb29 {
  padding-bottom: 29px;
}
.pb30 {
  padding-bottom: 30px;
}
.pb31 {
  padding-bottom: 31px;
}
.pb32 {
  padding-bottom: 32px;
}
.pb33 {
  padding-bottom: 33px;
}
.pb34 {
  padding-bottom: 34px;
}
.pb35 {
  padding-bottom: 35px;
}
.pb36 {
  padding-bottom: 36px;
}
.pb37 {
  padding-bottom: 37px;
}
.pb38 {
  padding-bottom: 38px;
}
.pb39 {
  padding-bottom: 39px;
}
.pb40 {
  padding-bottom: 40px;
}
.pb41 {
  padding-bottom: 41px;
}
.pb42 {
  padding-bottom: 42px;
}
.pb43 {
  padding-bottom: 43px;
}
.pb44 {
  padding-bottom: 44px;
}
.pb45 {
  padding-bottom: 45px;
}
.pb46 {
  padding-bottom: 46px;
}
.pb47 {
  padding-bottom: 47px;
}
.pb48 {
  padding-bottom: 48px;
}
.pb49 {
  padding-bottom: 49px;
}
.pb50 {
  padding-bottom: 50px;
}

// left
.pl0 {
  padding-left: 0px;
}
.pl1 {
  padding-left: 1px;
}
.pl2 {
  padding-left: 2px;
}
.pl3 {
  padding-left: 3px;
}
.pl4 {
  padding-left: 4px;
}
.pl5 {
  padding-left: 5px;
}
.pl6 {
  padding-left: 6px;
}
.pl7 {
  padding-left: 7px;
}
.pl8 {
  padding-left: 8px;
}
.pl9 {
  padding-left: 9px;
}
.pl10 {
  padding-left: 10px;
}
.pl11 {
  padding-left: 11px;
}
.pl12 {
  padding-left: 12px;
}
.pl13 {
  padding-left: 13px;
}
.pl14 {
  padding-left: 14px;
}
.pl15 {
  padding-left: 15px;
}
.pl16 {
  padding-left: 16px;
}
.pl17 {
  padding-left: 17px;
}
.pl18 {
  padding-left: 18px;
}
.pl19 {
  padding-left: 19px;
}
.pl20 {
  padding-left: 20px;
}
.pl21 {
  padding-left: 21px;
}
.pl22 {
  padding-left: 22px;
}
.pl23 {
  padding-left: 23px;
}
.pl24 {
  padding-left: 24px;
}
.pl25 {
  padding-left: 25px;
}
.pl26 {
  padding-left: 26px;
}
.pl27 {
  padding-left: 27px;
}
.pl28 {
  padding-left: 28px;
}
.pl29 {
  padding-left: 29px;
}
.pl30 {
  padding-left: 30px;
}
.pl31 {
  padding-left: 31px;
}
.pl32 {
  padding-left: 32px;
}
.pl33 {
  padding-left: 33px;
}
.pl34 {
  padding-left: 34px;
}
.pl35 {
  padding-left: 35px;
}
.pl36 {
  padding-left: 36px;
}
.pl37 {
  padding-left: 37px;
}
.pl38 {
  padding-left: 38px;
}
.pl39 {
  padding-left: 39px;
}
.pl40 {
  padding-left: 40px;
}
.pl41 {
  padding-left: 41px;
}
.pl42 {
  padding-left: 42px;
}
.pl43 {
  padding-left: 43px;
}
.pl44 {
  padding-left: 44px;
}
.pl45 {
  padding-left: 45px;
}
.pl46 {
  padding-left: 46px;
}
.pl47 {
  padding-left: 47px;
}
.pl48 {
  padding-left: 48px;
}
.pl49 {
  padding-left: 49px;
}
.pl50 {
  padding-left: 50px;
}

// right
.pr0 {
  padding-right: 0px;
}
.pr1 {
  padding-right: 1px;
}
.pr2 {
  padding-right: 2px;
}
.pr3 {
  padding-right: 3px;
}
.pr4 {
  padding-right: 4px;
}
.pr5 {
  padding-right: 5px;
}
.pr6 {
  padding-right: 6px;
}
.pr7 {
  padding-right: 7px;
}
.pr8 {
  padding-right: 8px;
}
.pr9 {
  padding-right: 9px;
}
.pr10 {
  padding-right: 10px;
}
.pr11 {
  padding-right: 11px;
}
.pr12 {
  padding-right: 12px;
}
.pr13 {
  padding-right: 13px;
}
.pr14 {
  padding-right: 14px;
}
.pr15 {
  padding-right: 15px;
}
.pr16 {
  padding-right: 16px;
}
.pr17 {
  padding-right: 17px;
}
.pr18 {
  padding-right: 18px;
}
.pr19 {
  padding-right: 19px;
}
.pr20 {
  padding-right: 20px;
}
.pr21 {
  padding-right: 21px;
}
.pr22 {
  padding-right: 22px;
}
.pr23 {
  padding-right: 23px;
}
.pr24 {
  padding-right: 24px;
}
.pr25 {
  padding-right: 25px;
}
.pr26 {
  padding-right: 26px;
}
.pr27 {
  padding-right: 27px;
}
.pr28 {
  padding-right: 28px;
}
.pr29 {
  padding-right: 29px;
}
.pr30 {
  padding-right: 30px;
}
.pr31 {
  padding-right: 31px;
}
.pr32 {
  padding-right: 32px;
}
.pr33 {
  padding-right: 33px;
}
.pr34 {
  padding-right: 34px;
}
.pr35 {
  padding-right: 35px;
}
.pr36 {
  padding-right: 36px;
}
.pr37 {
  padding-right: 37px;
}
.pr38 {
  padding-right: 38px;
}
.pr39 {
  padding-right: 39px;
}
.pr40 {
  padding-right: 40px;
}
.pr41 {
  padding-right: 41px;
}
.pr42 {
  padding-right: 42px;
}
.pr43 {
  padding-right: 43px;
}
.pr44 {
  padding-right: 44px;
}
.pr45 {
  padding-right: 45px;
}
.pr46 {
  padding-right: 46px;
}
.pr47 {
  padding-right: 47px;
}
.pr48 {
  padding-right: 48px;
}
.pr49 {
  padding-right: 49px;
}
.pr50 {
  padding-right: 50px;
}
