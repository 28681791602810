@import '../../style/constantes.less';

.navbar-menu {
  display: flex;
  margin-bottom: 10px;
  &-item {
    height: 42px;
    background-color: @substrate-color;
    font-family: @font-roboto;
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    padding-top: 10px;
    margin-right: 6px;
    cursor: pointer;
  }
  .ant-btn {
    width: 216px;
  }
  .notification-dropdown {
    cursor: pointer;
  }
}

@primary-color: #1DA57A;