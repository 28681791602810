@import '../../../style/constantes.less';

.dictionaries-list {
  background-color: white;
  padding-top: 10px;
  margin-left: -20px !important;
  margin-right: -20px !important;
  margin-bottom: 20px;

  .row {
    padding: 10px 20px;
    min-height: 41.14px;
    font-family: @font-roboto;
    font-size: 12px;
    cursor: pointer;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    &:hover {
      border-color: rgb(209, 209, 209);
    }
  }

  .even-row {
    background-color: #f4f4f4;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    &:hover {
      border-color: rgb(209, 209, 209);
    }
  }

  .list-rows {
    overflow-y: auto;
    width: 100%;
  }
}

@primary-color: #1DA57A;