.notifications {
  //width: 470px;
  //height: 295px;
  //background-color: white;
  //border: 1px solid #f3f3f3 !important;
  //box-shadow: 1px 1px 5px #f3f3f3;
  //color: red;
  //overflow: auto;
  //padding: 10px 20px 20px 20px;
  ////font-weight: bold;

  .title {
    text-align: left;
  }

  .even-number {
    background-color: #f4f4f4;
  }

  .ant-divider {
    margin: 0 !important;
  }
  .no-notification-title {
    margin-top: 100px;
    text-align: center;
    font-size: 30px;
    color: grey;
  }

  .unread {
    font-weight: bold;
  }
}

@primary-color: #1DA57A;